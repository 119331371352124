import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const NewsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  gap: 2rem;
  margin: 1.6rem;
  height: auto;
`;

const Card = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  width: 400px;
  height: 200px;
  position: relative;
  background: #0d0d0d;
  padding: 1rem; /* Add padding for better content spacing */

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(255, 255, 255, 0.4);
    background: #2c2c2c;
  }
`;

const DateData = styled.div`
  color: #ffffff;
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const CardHeader = styled.div`
  background: #444;
  color: #ffffff;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ReadMore = styled(Link)`
  padding: 20px;
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #ffcc00;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const PageButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  background-color: #444;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #555;
  }

  &:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
`;

const EmptyMessage = styled.div`
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
`;

const LoadingMessage = styled.div`
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
`;

const NewsPage = () => {
    const [announcements, setNews] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6; // Number of items to display per page

    const fetchNews = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/news`);
            if (!response.ok) {
                window.location.reload();
            }
            const data = await response.json();
            setNews(data.news);
        } catch (error) {
            console.error('Error fetching news:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
      fetchNews();
    }, []);

    // Calculate the total number of pages
    const totalPages = Math.ceil(announcements.length / itemsPerPage);

    // Get the current items to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = announcements.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            {loading ? (
                <LoadingMessage>Loading announcements...</LoadingMessage>
            ) : error ? (
                <EmptyMessage>{error}</EmptyMessage>
            ) : (
                <>
                    <NewsContainer>
                        {currentItems.length > 0 ? (
                            currentItems.map((news, index) => (
                                <Card key={index}>
                                    <CardHeader>{news.title}</CardHeader>
                                    <ReadMore
                                        to={{
                                            pathname: `/announcement/${news.id}`,
                                        }}
                                        state={{ id: news.id, date: news.created_at, title: news.title, description: news.description }}
                                        title={`Read more about ${news.title}`}
                                        aria-label={`Read more about ${news.title}`}
                                    >
                                        Read More
                                    </ReadMore>
                                    <DateData>{formatDate(news.created_at)}</DateData>
                                </Card>
                            ))
                        ) : (
                            <EmptyMessage>No announcements available</EmptyMessage>
                        )}
                    </NewsContainer>
                    <PaginationContainer>
                        <PageButton
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </PageButton>
                        <span style={{ color: 'white' }}>{`${currentPage} / ${totalPages}`}</span>
                        <PageButton
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </PageButton>
                    </PaginationContainer>
                </>
            )}
        </>
    );
};

const formatDate = (dateString) => {
  // Create a Date object from the UTC date string
  const date = new Date(dateString);

  // Get the year, month, and day in UTC
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');

  // Get the hours, minutes, and seconds in UTC
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Return in YYYY-MM-DD HH:MM:SS format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export default NewsPage;
