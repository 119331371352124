import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  LabelPrimaryStyled,
  LabelDangerStyled,
  LabelSuccessStyled,
} from '../utils/LabelUtils';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
`;

const RankingContainer = styled.div`
  flex: 3;
  margin-right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 25%;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const RankingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const TableData = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;
const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 12px 20px; /* Increase padding for larger buttons */
  margin: 0 8px; /* Adjust margin if needed */
  background-color: ${(props) => (props.active ? '#2c3e50' : '#bdc3c7')};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px; /* Increase font size if needed */

  &:hover {
    background-color: #2c3e50;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #7f8c8d;
  }
`;

const Ellipsis = styled.span`
  padding: 10px;
  color: white;
`;

// Main component
const RichRankingPage = () => {
  const [richList, setRichList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('All Players');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page

  // Simulate API call to fetch rankings
  useEffect(() => {

    const fetchRichList = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/richlist`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setRichList(data.richlist);
        } catch (error) {
          console.error('Error fetching player ranking:', error);
        }
      };

      fetchRichList();
  }, []);

  // Filter rankings based on the search term and selected category
  const filteredRichList = richList
    .filter((rankItem) =>
      rankItem.chaname.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // Get current page rankings
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRankings = filteredRichList.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredRichList.length / itemsPerPage);

  const getPaginationItems = () => {
    const pageNumbers = [];
    const visiblePages = 3; // Number of pages to show before and after the current page
  
    if (totalPages <= 5) {
      // If total pages are 5 or fewer, show all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always show the first page
      pageNumbers.push(1);
  
      // Show ellipsis if current page is far from the beginning
      if (currentPage > visiblePages + 2) {
        pageNumbers.push('ellipsis-left');
      }
  
      // Show pages around the current page
      for (let i = Math.max(2, currentPage - visiblePages); i <= Math.min(totalPages - 1, currentPage + visiblePages); i++) {
        pageNumbers.push(i);
      }
  
      // Show ellipsis if current page is far from the end
      if (currentPage < totalPages - visiblePages - 1) {
        pageNumbers.push('ellipsis-right');
      }
  
      // Always show the last page
      pageNumbers.push(totalPages);
    }
  
    // Ensure ellipses are not repeated
    return pageNumbers.filter((item, index, self) =>
      index === self.indexOf(item) && !(item === 'ellipsis-left' && self[index - 1] === 'ellipsis-left') &&
      !(item === 'ellipsis-right' && self[index + 1] === 'ellipsis-right')
    );
  };

  return (
    <PageContainer>
      <ContentWrapper>
        {/* Ranking Table Section */}
        <RankingContainer>
          <Title>Rich Ranking</Title>
          <SearchInput
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <RankingTable>
            <thead>
              <tr>
                <TableHeader>Rank</TableHeader>
                <TableHeader>Name</TableHeader>
                <TableHeader>School</TableHeader>
                <TableHeader>Money</TableHeader>
              </tr>
            </thead>
            <tbody>
              {currentRankings.map((rankItem, index) => (
                <TableComponent
                rownum={rankItem.index}
                rankItem={rankItem}
                />
              ))}
            </tbody>
          </RankingTable>

         {/* Pagination */}
         <Pagination>
            <PageButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous
            </PageButton>

            {getPaginationItems().map((pageNumber, index) => {
                if (pageNumber === 'ellipsis-left' || pageNumber === 'ellipsis-right') {
                return <Ellipsis key={index}>...</Ellipsis>;
                }

                return (
                <PageButton
                    key={pageNumber}
                    active={currentPage === pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                >
                    {pageNumber}
                </PageButton>
                );
            })}

            <PageButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                Next
            </PageButton>
            </Pagination>
        </RankingContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

const TableComponent = ({ rankItem }) => {
    // Define your base path for images
    const campusBasePath = '../images/campus/'; // Adjust the path according to your folder structure
  
    // Generate the complete image path
    const imageCampusPath = `${campusBasePath}${rankItem.chaschool}.png`;

  
    return (
        <TableRow>
            <TableData>{rankItem.rownum}</TableData>
            <TableData>{rankItem.chaname}</TableData>
            <TableData><img src={imageCampusPath} alt="class icon" /></TableData>
            <TableData><LabelDangerStyled>{rankItem?.chamoney?.toLocaleString()}</LabelDangerStyled></TableData>
      </TableRow>
    );
  };

export default RichRankingPage;
