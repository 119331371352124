import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getDownloadServerName, getDownloadCategoryName } from '../utils/DownloadUtils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const FormWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
  width: 100%;
  max-width: 600px;
`;

const FormTitle = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 96%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Button = styled.button`
  padding: 12px 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #27ae60;
  }
`;

const BackButton = styled.button`
  padding: 12px 20px;
  background-color: #c9302c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #e74c3c;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

// AddDownloadPage Component
const AddDownloadPage = () => {
  const navigate = useNavigate();
  
  // State for form fields
  const [name, setName] = useState('');
  const [version, setVersion] = useState('');
  const [link, setLink] = useState('');
  const [categoryId, setCategoryId] = useState(''); 
  const [serverId, setServerId] = useState(''); 
  const [categoryList, setCategoryList] = useState([]);
  const [serverList, setServerList] = useState([]);
  const [activeStatus, setActiveStatus] = useState(''); // Default to active (1)
  const [serverName, setServerName] = useState('');

  useEffect(() => {
    // Simulate fetching categories
    const serverCategories = ["GDrive", "Mediafire", "MEGA"].map((id) => ({
      id,
      name: getDownloadServerName(id),
    }));
    
    const categoryCategories = ["Client", "Patch"].map((id) => ({
        id,
        name: getDownloadCategoryName(id),
      }));

      setServerList(serverCategories);
      setCategoryList(categoryCategories);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newItem = {
        category: categoryId,
        server: serverId,
        name: name, 
        version,
        link,
      status: Number(activeStatus),
    };
    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/download/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}` // Include API key only
        },
        body: JSON.stringify(newItem),
      });

      if (!response.ok) {
        throw new Error('Failed to add download link');
      }
      alert('Download link added successfully');
      navigate('/download/panel'); // Redirect to the download list page
    } catch (error) {
      console.error('Error adding download link:', error);
    }
  };

  return (
    <PageContainer>
      <FormWrapper>
        <FormTitle>Add New Download Link</FormTitle>
        <form onSubmit={handleSubmit}>

          {/* Category Dropdown */}
          <Select
            value={categoryId}  // Bind to categoryId (id of selected category)
            onChange={(e) => {
              const selectedCategoryId = e.target.value;
              const selectedCategory = categoryList.find(cat => cat.id === Number(selectedCategoryId));
              setCategoryId(selectedCategoryId);
            }}
            required
          >
            <option value="">Select Category</option>
            {categoryList.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </Select>

          {/* Server Dropdown */}
          <Select
            value={serverId}
            onChange={(e) => {
              const selectedServerId = e.target.value;
              const selectedServer = serverList.find(server => server.id === Number(selectedServerId));
              setServerId(selectedServerId);
              setServerName(selectedServer ? selectedServer.name : '');
            }}
            required
          >
            <option value="">Select Server</option>
            {serverList.map((server) => (
              <option key={server.id} value={server.id}>
                {server.name}
              </option>
            ))}
          </Select>

          {/* Active Status Dropdown */}
          <Select
            value={activeStatus}
            onChange={(e) => setActiveStatus(e.target.value)}
            required
          >
            <option value="">Select Status</option>
            <option value="1">Active</option>
            <option value="0">Hidden</option>
          </Select>

          {/* Name, Version, and Link Fields */}
          <Input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Version"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            required
          />
          <ButtonContainer>
            <BackButton 
                type="button" // This prevents it from being treated as a submit button
                onClick={() => {
                    navigate(-1); // Navigate back to the previous page
                }}
            >
                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
                Back
            </BackButton>
            <Button type="submit">Add Download</Button>
        </ButtonContainer>
        </form>
      </FormWrapper>
    </PageContainer>
  );
};

export default AddDownloadPage;
