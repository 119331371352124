import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SocialMediaWidget from './SocialMediaWidget'; 
import StatusWidget from './StatusWidget';


const SidebarContainer = styled.div`
  width: 100%; /* Adjust the width as needed */
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Add some space between widgets */
`;

const Widget = styled.div`
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  background:#171717;
  z-index: 1
`;

const WidgetTitle = styled.h3`
  text-align: center;
  margin-bottom: 1rem;
  color: white;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.th`
  text-align: left;
  color: white; 
  padding: 0.5rem;
`;

const TableCell = styled.td`
  text-align: left;
  padding: 0.5rem;
  color: rgba(255, 255, 255, 0.5);
  /* Add specific styling for the Rank column */
  &:first-of-type {
    color: white; /* White text color for the Rank column */
  }
  padding: 8px; /* Add padding if needed */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Adjust color and style as needed */
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid white; /* Adjust color and style as needed */
  
  &:last-of-type {
    border-bottom: none; /* Remove border for the last row if you prefer */
  }
`;


const Sidebar = () => {
  const [onlinePlayers, setOnlineCount] = useState(null);
  const [playerRanks, setPlayerRank] = useState([]);
  const [error, setError] = useState(null);

  const fetchOnlineCharacters = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/onlineplayers`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data && data.user && data.user.count !== undefined) {
        setOnlineCount(data.user.count);  // Access the count from user object
      } else {
        throw new Error('Failed to fetch online characters: Data is null or empty');
      }
    } catch (error) {
      console.error('Error fetching online characters:', error);  // Log the error
      setError(error.message);
    }
  };

  const fetchPlayerRanking = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/contributionlist`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const top10Players = Array.isArray(data.contributionlist) ? data.contributionlist.slice(0, 10) : [];
      setPlayerRank(top10Players);
    } catch (error) {
      console.error('Error fetching player ranking:', error);
    }
  };

  useEffect(() => {
    fetchPlayerRanking();
    fetchOnlineCharacters();

    const intervalId = setInterval(fetchOnlineCharacters, 30000); // Refresh every 60 seconds

    return () => clearInterval(intervalId);
  }, []);


  const TableComponent = ({ player }) => {
    // Define your base path for images
    const basePath = '../images/class/'; // Adjust the path according to your folder structure
  
    // Generate the complete image path
    const imagePath = `${basePath}${player.chaclass}.gif`;
  
    return (
      <StyledTableRow>
      <TableCell>{player.rownum}</TableCell>
      <TableCell><strong>{player.chaname}</strong></TableCell>
      <TableCell>{player.chalevel}</TableCell>
      <TableCell>{player.contributionpoint.toLocaleString()}</TableCell>
      <TableCell><img src={imagePath} alt="class icon" /></TableCell>
    </StyledTableRow>
    );
  };

  return (
    <SidebarContainer>
      <StatusWidget onlinePlayers={onlinePlayers} /> {/* Use the StatusWidget */}
      <Widget>
        <WidgetTitle>Contribution Ranking</WidgetTitle>
        <table cellSpacing="0" width="100%">
          <thead>
            <tr>
              <TableHead>Rank</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Level</TableHead>
              <TableHead>Contribution</TableHead>
              <TableHead>Class</TableHead>
            </tr>
          </thead>
          <tbody>
            {playerRanks.map(player => (
              <TableComponent
                key={player.rownum} // Adjust the key to use a unique value from player
                player={player}
              />
            ))}
          </tbody>
        </table>
      </Widget>
      <Widget>
        <WidgetTitle>Club Leader</WidgetTitle>
        <table cellSpacing="0" width="100%">
          <thead>
            <tr>
              <TableHead>Area</TableHead>
              <TableHead>Guild</TableHead>
              <TableHead>Tax</TableHead>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </Widget>
      <SocialMediaWidget /> {/* Include the SocialMediaWidget */}
    </SidebarContainer>
  );
};

export default Sidebar;