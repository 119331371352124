import React, { useState, useEffect, useCallback,useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowsAlt, faArrowLeft,faCheckSquare, faTimesSquare, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../components/UserContext'; // Adjust the import as necessary
import { getRechargeCategoryName } from '../utils/RechargeCategoryUtils.js';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`;

const RankingContainer = styled.div`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
`;

const RankingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const TableData = styled.td`
  padding: 10px;
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
`;

const PageButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #34495e;
  }

  &.active {
    background-color: #c9302c;
  }
`;

const Ellipsis = styled.span`
  padding: 10px;
  color: white;
`;

// Button styling
export const SubmitButton = styled.button`
  width: 40%;
  display: inline-block;
  padding: 10px 10px;
  font-size: 16px;
  color: white;
  background-color: red; /* Blue background */
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0392b; /* Darker blue on hover */
  }
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#c0392b' : '#27ae60')};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: ${props => (props.status === 'inactive' || props.status === 'admin' ? '#e74c3c' : '#2ecc71')};
    transform: translateY(-2px); /* Button hover animation */
  }
`;

const UpdateButton = styled(Button)`
  background-color: #c9302c;
  border-radius: 5px 5px 5px 5px; /* Curves only the right side */
  &:hover {
    background-color: #c0392b;
  }
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 20%; /* Adjust width for better fit */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Dropdown = styled.select`
  padding: 10px;
  width: 20%; /* Adjust width for better fit */
  margin-bottom: 20px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const BackButton = styled.button`
  padding: 12px 20px;
  background-color: #c9302c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #e74c3c;
  }
`;

const UpdateAcceptButton = styled(Button)`
  background-color: #5cb85c;
  border-radius: 5px 5px 5px 5px; /* Curves only the right side */
  &:hover {
    background-color: #4cae4c;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const LoadingSpinner = styled(FontAwesomeIcon)`
  font-size: 48px;
  color: #2c3e50;
  animation: spin 1s infinite linear;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Main component
const Rechargelog = () => {
    const [rechargeLog, setRechargeLog] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Number of items per page
    const navigate = useNavigate(); // Initialize useNavigate
    const [searchTerm, setSearchTerm] = useState('');
    const [category, setCategory] = useState(''); // Add category state
    const [categoryList, setCategoryList] = useState([]); // Store categories
    const [loading, setLoading] = useState(false); // Loading state
    const [pageSize] = useState(10); // Fixed page size
    const [totalRecords, setTotalRecords] = useState(0);
    const { userData, setUserData } = useContext(UserContext);

    // Debounce function
    const useDebounce = (value, delay) => {
      const [debouncedValue, setDebouncedValue] = useState(value);

      useEffect(() => {
          const handler = setTimeout(() => {
              setDebouncedValue(value);
          }, delay);

          return () => {
              clearTimeout(handler);
          };
      }, [value, delay]);

      return debouncedValue;
  };

  
  const debouncedSearchTerm = useDebounce(searchTerm, 10);

  const categories = ['Peso', 'PP', 'M'].map((id) => ({
    id,
    name: getRechargeCategoryName(id),
  }));

    const fetchRechargeLog = useCallback(async () => {
      // setLoading(true);
      try {
        const token = localStorage.getItem('authToken');
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/allrechargelog?search=${debouncedSearchTerm}&page=${currentPage}&pageSize=${pageSize}`, {
            headers: {
              "Authorization": `Bearer ${token}` // Include API key only
            },
          });
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setRechargeLog(data.rechargelog);
          setTotalRecords(data.totalRecords);
      } catch (error) {
          console.error('Error fetching recharge logs:', error);
      } finally {
          // setLoading(false);
      }
  }, [currentPage, pageSize, debouncedSearchTerm]); // Include debouncedSearchTerm as a dependency

  useEffect(() => {
      fetchRechargeLog();
      setCategoryList(categories); // Set the category list (ensure `categories` is defined)
  }, [debouncedSearchTerm, currentPage, pageSize]); // Call fetchR

    // Handle add item button click
    const handleAcceptAndDecline = async(orderId,status,username,kbpoint) => {
      
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/transaction/status/update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}` // Include API key only
          },
          body: JSON.stringify({
            order_id: orderId,
            transaction_status: status,
            username,
            kbpoint
          }),
        });
    
        if (response.ok) {
          fetchRechargeLog()
          if(status === 'settlement'){
            setUserData((prevData) => ({
              ...prevData,
              kbpoint: +prevData.kbpoint + +kbpoint,
            }));
          }
        } else {
          const errorData = await response.json();
        }
      } catch (error) {
        console.error('Error updating transaction:', error);
      }
    };

    const TableComponent = ({rankItem }) => {
        const paymentBasePath = '../images/payment/';
        const statusBasePath = '../images/misc/';
        const imagePaymentPath = `${paymentBasePath}${rankItem?.payment_method?.toLowerCase()}.png`;
        const imageStatusPath = `${statusBasePath}${rankItem.transaction_status}.png`;
        const imagePointStatusPath = `${statusBasePath}${rankItem.point_status}`;
        
        return (
          <TableRow>
            <TableData><img src={imagePaymentPath} alt="class icon" style={{ height: "20px" }} /></TableData>
            <TableData>{rankItem.order_id}</TableData>
            <TableData>{rankItem.username}</TableData>
            <TableData>{formatDate(rankItem.transaction_time)}</TableData>
            <TableData><img src={imageStatusPath} alt="class icon" style={{ height: "20px" }} /></TableData>
            <TableData><img src={imagePointStatusPath} alt="class icon" style={{ height: "20px" }} />{rankItem.kbpoint}</TableData>
            <TableData>{rankItem.price}</TableData>
            <TableData>{rankItem.reference}</TableData>
            <TableData>
                {rankItem.transaction_status !== 'deny' && rankItem.transaction_status !== 'settlement' && ( <>
                  <UpdateAcceptButton
                    style={{ marginRight: '10px' }}
                    onClick={() => handleAcceptAndDecline(rankItem.order_id, 'settlement',rankItem.username,rankItem.kbpoint)}
                  >
                    <FontAwesomeIcon icon={faCheckSquare} />
                    Accept
                  </UpdateAcceptButton>
                  <UpdateButton
                    style={{ marginRight: '10px' }}
                    onClick={() => handleAcceptAndDecline(rankItem.order_id, 'deny',rankItem.username,rankItem.kbpoint)}
                  >
                    <FontAwesomeIcon icon={faCheckSquare} />
                    Decline
                  </UpdateButton>
                  </>)
                }
            </TableData>
            <TableData>
                <UpdateButton onClick={() => alert('move')}>
                    <FontAwesomeIcon icon={faArrowsAlt} />
                    Move
                </UpdateButton>
            </TableData>

          </TableRow>
        );
      };

    // Filter rankings based on the search term
  const filteredRechargeLogs = rechargeLog
  .filter((rankItem) =>{
    const matchesSearchTerm = rankItem?.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    rankItem?.order_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    rankItem?.reference?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = category === '' || rankItem?.payment_method === category; // Filter by category, if selected
    return matchesSearchTerm && matchesCategory; // Match both search and category
    });

    const totalPages = Math.ceil(totalRecords / pageSize);

    const getPaginationButtons = () => {
      const maxPageButtons = 5; // Maximum visible page buttons at a time
      const pageButtons = [];
    
      if (totalPages <= maxPageButtons) {
        // Show all page buttons if the total number is within the limit
        for (let i = 1; i <= totalPages; i++) {
          pageButtons.push(
            <PageButton
              key={i}
              onClick={() => setCurrentPage(i)}
              className={currentPage === i ? 'active' : ''}
            >
              {i}
            </PageButton>
          );
        }
      } else {
        // Always show Page 1
        pageButtons.push(
          <PageButton
            key={1}
            onClick={() => setCurrentPage(1)}
            className={currentPage === 1 ? 'active' : ''}
          >
            1
          </PageButton>
        );
    
        // Add ellipsis if currentPage is far from Page 1
        if (currentPage > 3) {
          pageButtons.push(<span key="ellipsis1">...</span>);
        }
    
        // Display the range of pages around the current page
        const startPage = Math.max(2, currentPage - 2); // Start from the second page
        const endPage = Math.min(totalPages - 1, currentPage + 2); // End before the last page
    
        for (let i = startPage; i <= endPage; i++) {
          pageButtons.push(
            <PageButton
              key={i}
              onClick={() => setCurrentPage(i)}
              className={currentPage === i ? 'active' : ''}
            >
              {i}
            </PageButton>
          );
        }
    
        // Add ellipsis before the last page if currentPage is far from the last page
        if (currentPage < totalPages - 2) {
          pageButtons.push(<span key="ellipsis2">...</span>);
        }
    
        // Always show the last page
        pageButtons.push(
          <PageButton
            key={totalPages}
            onClick={() => setCurrentPage(totalPages)}
            className={currentPage === totalPages ? 'active' : ''}
          >
            {totalPages}
          </PageButton>
        );
      }
    
      return pageButtons;
    };

  return (
    <PageContainer>
      <ContentWrapper>
        <RankingContainer>
          <BackButton onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
              Back
            </BackButton>
          <Title>Recharge Log</Title>

          {loading ? (
            <LoadingContainer>
              <LoadingSpinner icon={faSpinner} />
            </LoadingContainer>
          ) : ( <>
          <SearchInput
            type="text"
            placeholder="Search by orderid or username or reference..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          <Dropdown value={category} onChange={(e) => setCategory(e.target.value)}> 
            <option value="">All Categories</option>
            {categoryList.map((cat) => (
                <option key={cat.id} value={cat.id}>{cat.name}</option> // Ensure value is the name (string) or modify based on how your categories are stored
            ))}
            </Dropdown>
          <RankingTable>
            <thead>
              <tr>
                <TableHeader>Gate</TableHeader>
                <TableHeader>OrderID</TableHeader>
                <TableHeader>Name</TableHeader>
                <TableHeader>Time</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>R-coin</TableHeader>
                <TableHeader>Price</TableHeader>
                <TableHeader>Reference</TableHeader>
                <TableHeader>Action</TableHeader>
                <TableHeader>Move</TableHeader>
              </tr>
            </thead>
            <tbody>
            {filteredRechargeLogs.map((rankItem) => (
                <TableComponent
                key={rankItem.order_id} // Ensure a unique key
                rankItem={rankItem} // Pass the entire rankItem object
                />
            ))}
            </tbody>
          </RankingTable>

          <PaginationContainer>
            <PageButton
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </PageButton>

            {getPaginationButtons()}

            <PageButton
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </PageButton>
          </PaginationContainer>
          </>)}
        </RankingContainer>
        
      </ContentWrapper>
    </PageContainer>
  );
};

const formatDate = (dateString) => {
  // Create a Date object from the UTC date string
  const date = new Date(dateString);

  // Get the year, month, and day in UTC
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');

  // Get the hours, minutes, and seconds in UTC
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Return in YYYY-MM-DD HH:MM:SS format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export default Rechargelog;
