import React, { useState, useEffect,useCallback  } from 'react';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`;

const RankingContainer = styled.div`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 20%;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const RankingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const TableData = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const BackButton = styled.button`
  padding: 12px 20px;
  background-color: #c9302c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #e74c3c;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
`;

const PageButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #34495e;
  }

  &.active {
    background-color: #c9302c;
  }
`;

const ShopPanelPurchaseLogPage = () => {
  const [purchaseLog, setPurchaseLog] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10); // Fixed page size
  const [totalRecords, setTotalRecords] = useState(0);
  const navigate = useNavigate();

  // Debounce function
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay); 

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

const debouncedSearchTerm = useDebounce(searchTerm, 1);

const fetchRankings = useCallback(async () => {
  // setLoading(true);
  try {
    const token = localStorage.getItem('authToken');
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/shoppurchaselog?search=${debouncedSearchTerm}&page=${currentPage}&pageSize=${pageSize}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      },
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setPurchaseLog(data.shoplog);
    setTotalRecords(data.totalRecords);
  } catch (error) {
      console.error('Error fetching recharge logs:', error);
  } finally {
      // setLoading(false);
  }
}, [currentPage, pageSize, debouncedSearchTerm]); // Include debouncedSearchTerm as a dependency

useEffect(() => {
  fetchRankings();
}, [debouncedSearchTerm, currentPage, pageSize]); // Call fetchR


  const filteredPurchaseLogs = purchaseLog.filter((log) =>
    log.itemname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    log.useruid.toString().includes(searchTerm)
  );

  const totalPages = Math.ceil(totalRecords / pageSize);

  const getPaginationButtons = () => {
    const maxPageButtons = 5; // Maximum visible page buttons at a time
    const pageButtons = [];
  
    if (totalPages <= maxPageButtons) {
      // Show all page buttons if the total number is within the limit
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <PageButton
            key={i}
            onClick={() => setCurrentPage(i)}
            className={currentPage === i ? 'active' : ''}
          >
            {i}
          </PageButton>
        );
      }
    } else {
      // Always show Page 1
      pageButtons.push(
        <PageButton
          key={1}
          onClick={() => setCurrentPage(1)}
          className={currentPage === 1 ? 'active' : ''}
        >
          1
        </PageButton>
      );
  
      // Add ellipsis if currentPage is far from Page 1
      if (currentPage > 3) {
        pageButtons.push(<span key="ellipsis1">...</span>);
      }
  
      // Display the range of pages around the current page
      const startPage = Math.max(2, currentPage - 2); // Start from the second page
      const endPage = Math.min(totalPages - 1, currentPage + 2); // End before the last page
  
      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <PageButton
            key={i}
            onClick={() => setCurrentPage(i)}
            className={currentPage === i ? 'active' : ''}
          >
            {i}
          </PageButton>
        );
      }
  
      // Add ellipsis before the last page if currentPage is far from the last page
      if (currentPage < totalPages - 2) {
        pageButtons.push(<span key="ellipsis2">...</span>);
      }
  
      // Always show the last page
      pageButtons.push(
        <PageButton
          key={totalPages}
          onClick={() => setCurrentPage(totalPages)}
          className={currentPage === totalPages ? 'active' : ''}
        >
          {totalPages}
        </PageButton>
      );
    }
  
    return pageButtons;
  };
  

  return (
    <PageContainer>
      <ContentWrapper>
        <RankingContainer>
          <BackButton onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
            Back
          </BackButton>
          <Title>Shop Panel - Log</Title>
          <SearchInput
            type="text"
            placeholder="Search by id or name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <RankingTable>
            <thead>
              <tr>
                <TableHeader>ID</TableHeader>
                <TableHeader>Name</TableHeader>
                <TableHeader>Price</TableHeader>
                <TableHeader>Date</TableHeader>
                <TableHeader>Act</TableHeader>
              </tr>
            </thead>
            <tbody>
              {filteredPurchaseLogs.map((rankItem) => (
                <TableComponent key={rankItem.purkey} rankItem={rankItem} />
              ))}
            </tbody>
          </RankingTable>
          <PaginationContainer>
            <PageButton
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </PageButton>

            {getPaginationButtons()}

            <PageButton
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </PageButton>
          </PaginationContainer>
        </RankingContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

const TableComponent = ({ rankItem }) => {
  const campusBasePath = '/images/';
  const imageCampusPath = `${campusBasePath}${rankItem.purflag}.png`;
  const imageItemBasePath = '/images/item/';
  const imageItemPath = `${imageItemBasePath}${rankItem.itemsub}.png`;
  return (
    <TableRow>
      <TableData>{rankItem.useruid}</TableData>
      <TableData>
        <img src={imageItemPath} alt="class icon" style={{ marginRight: '25px', width: '25px' }} />
        {rankItem.itemname}
      </TableData>
      <TableData>{rankItem.purprice}</TableData>
      <TableData>{rankItem.purdate}</TableData>
      <TableData><img src={imageCampusPath} alt="class icon" /></TableData>
    </TableRow>
  );
};

export default ShopPanelPurchaseLogPage;
