import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import GameDetails from './pages/GameDetails';
import About from './pages/About';
import Download from './pages/Download';
import NewsPage from './pages/NewsPage';
import './styles/global.css';
import styled from 'styled-components';
import Register from './pages/Register';
import AnnouncementCardDetail from './components/AnnouncementCardDetail';
import RechargeCart from './pages/RechargeCart';
import PlayerRankingPage from './pages/PlayerRankingPage';
import GuildRankingPage from './pages/GuildRankingPage';
import ScrollToTop from './components/ScrollToTop';
import InvoicePage from './pages/InvoicePage';
import UpdateProfile from './components/UpdateProfile';
import { UserProvider } from './components/UserContext';
import UserDetailsPage from './pages/UserDetailsPage';
import Shop from './pages/Shop';
import UserPanelList from './pages/UserPanelList';
import UserPanelEdit from './pages/UserPanelEdit';
import CharPanelList from './pages/CharPanelList';
import ShopPanelList from './pages/ShopPanelList';
import AddItemPage from './pages/AddItemPage';
import UpdateItemPage from './pages/UpdateItemPage';
import ShopPanelPurchaseLogPage from './pages/ShopPanelPurchaseLogPage';
import NewsPanelList from './pages/NewsPanelList';
import AddNewsPage from './pages/AddNewsPage';
import RechargePanelList from './pages/RechargePanelList';
import AddVoucherPage from './pages/AddVoucherPage';
import RechargePanelLog from './pages/RechargePanelLog';
import IPLogPanelList from './pages/IPLogPanelList';
import SendPanelList from './pages/SendPanelList';
import DownloadPanelList from './pages/DownloadPanelList';
import AddDownloadPage from './pages/AddDownloadPage';
import RichRankingPage from './pages/RichRankingPage';
import ContributionRankingPage from './pages/ContributionRankingPage';
import SuperAdminPanelList from './pages/SuperAdminPanelList';
import AddAdminPage from './pages/AddAdminPage';
import AddEmailTicketPage from './pages/AddEmailTicketPage';
import SuperAdminPanelLog from './pages/SuperAdminPanelLog';
import SuperAdminPanelUserLog from './pages/SuperAdminPanelUserLog';
import NewsPanelEdit from './pages/NewsPanelEdit';
import RechargePage from './pages/RechargePage';
import CouponPanelList from './pages/CouponPanelList';
import AddCouponPage from './pages/AddCouponPage';
import CouponPanelLog from './pages/CouponPanelLog';
import RechargeLog from './pages/RechargeLog';
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import NotAuthorized from './components/NotAuthorized';
import ChangePasswordPage from './pages/ChangePasswordPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import FileUpload from './pages/FileUpload';
import UpdateEmail from './components/UpdateEmail';
import UpdatePin from './components/UpdatePin';
import ForgotPassword from './components/ForgotPassword';

// Add a container with flexbox
const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  background: rgba(0,0,0,0.7);
`;

const Content = styled.div`
  flex: 1; /* Take up remaining space */
`;


const App = () => (
  <UserProvider>
    <Router>
      <ScrollToTop /> {/* Scrolls to top on route change */}
      <AppWrapper>
        <Navbar /> {/* Higher z-index for navbar */}
        <Content>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/download" element={<Download />} />
            <Route path="/register" element={<Register />} />
            <Route path="/cart/:id" element={<RechargeCart />} />
            <Route path="/announcement/:id" element={<AnnouncementCardDetail />} />
            <Route path="/ranking/player" element={<PlayerRankingPage />} />
            <Route path="/ranking/guild" element={<GuildRankingPage />} />
            <Route path="/invoice" element={<PrivateRoute><InvoicePage /></PrivateRoute>} />
            <Route path="/recharge/log" element={<PrivateRoute><RechargeLog /></PrivateRoute>} />
            <Route path="/profile/edit" element={<PrivateRoute><UpdateProfile /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><UserDetailsPage /></PrivateRoute>} />
            <Route path="/shop" element={<PrivateRoute><Shop /></PrivateRoute>} />
            <Route path="/ranking/contribution" element={<ContributionRankingPage />} />
            <Route path="/recharge" element={<PrivateRoute><RechargePage /></PrivateRoute>} />
            <Route path="/ranking/rich" element={<RichRankingPage />} />
            <Route path="/profile/edit/email" element={<PrivateRoute><UpdateEmail /></PrivateRoute>} />
            <Route path="/profile/edit/pin" element={<PrivateRoute><UpdatePin /></PrivateRoute>} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />

            <Route path="/user/panel" element={<PrivateRoute><UserPanelList /></PrivateRoute>} />
            <Route path="/user/:id" element={<PrivateRoute><UserPanelEdit /></PrivateRoute>} />
            <Route path="/char/panel" element={<PrivateRoute><CharPanelList /></PrivateRoute>} />
            <Route path="/shop/panel" element={<PrivateRoute><ShopPanelList /></PrivateRoute>} />
            <Route path="/shop/add" element={<PrivateRoute><AddItemPage /></PrivateRoute>} />
            <Route path="/shop/:id" element={<PrivateRoute><UpdateItemPage /></PrivateRoute>} /> {/* Update item page */}
            <Route path="/shop/view/log" element={<PrivateRoute><ShopPanelPurchaseLogPage /></PrivateRoute>} />
            <Route path="/news/panel" element={<PrivateRoute><NewsPanelList /></PrivateRoute>} />
            <Route path="/news/add" element={<PrivateRoute><AddNewsPage /></PrivateRoute>} />
            <Route path="/recharge/panel" element={<PrivateRoute><RechargePanelList /></PrivateRoute>} />
            <Route path="/recharge/add" element={<PrivateRoute><AddVoucherPage /></PrivateRoute>} />
            <Route path="/recharge/logs" element={<PrivateRoute><RechargePanelLog /></PrivateRoute>} />
            <Route path="/iplog/panel" element={<PrivateRoute><IPLogPanelList /></PrivateRoute>} />
            <Route path="/send/panel" element={<PrivateRoute><SendPanelList /></PrivateRoute>} />
            <Route path="/download/panel" element={<PrivateRoute><DownloadPanelList /></PrivateRoute>} />
            <Route path="/download/add" element={<PrivateRoute><AddDownloadPage /></PrivateRoute>} />
            <Route path="/admin/panel" element={<PrivateRoute><SuperAdminPanelList /></PrivateRoute>} />
            <Route path="/admin/add" element={<PrivateRoute><AddAdminPage /></PrivateRoute>} />
            <Route path="/admin/add/ticket" element={<PrivateRoute><AddEmailTicketPage /></PrivateRoute>} />
            <Route path="/admin/log" element={<PrivateRoute><SuperAdminPanelLog /></PrivateRoute>} />
            <Route path="/admin/user/log" element={<PrivateRoute><SuperAdminPanelUserLog /></PrivateRoute>} />
            <Route path="/news/:id" element={<PrivateRoute><NewsPanelEdit /></PrivateRoute>} />
            <Route path="/coupon/panel" element={<PrivateRoute><CouponPanelList /></PrivateRoute>} />
            <Route path="/coupon/add" element={<PrivateRoute><AddCouponPage /></PrivateRoute>} />
            <Route path="/coupon/log" element={<PrivateRoute><CouponPanelLog /></PrivateRoute>} />
            <Route path="/profile/edit/password" element={<PrivateRoute><ChangePasswordPage /></PrivateRoute>} />

            <Route path="/login" element={<Login />} />
            <Route path="/not-authorized" element={<NotAuthorized />} />
            <Route path="/fileupload" element={<FileUpload />} />
            <Route path="/terms" element={<TermsOfServicePage />} />
          </Routes>
        </Content>
        <Footer />
      </AppWrapper>
    </Router>
  </UserProvider>
);

export default App;
