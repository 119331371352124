import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../components/UserContext'; // Adjust the import as necessary
import UserDetailsTable from '../components/UserDetailsTable'; // Adjust the import as necessary
import UserInfoTable from '../components/UserInfoTable'; // Adjust the import as necessary
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  margin: auto;
  max-width: 80%;
`;

const LeftSection = styled.div`
  flex:1; /* Increased to make this section wider */
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  flex: 1; /* Adjusted to take up less space */
`;

const ActionButton = styled.button`
  background-color: #273c75;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  width: 30%;
  text-align: -webkit-left;

  &:hover {
    background-color: #2f4f72;
  }
`;

const UserDetailsPage = () => {
    const [userInfo, setUserInfo] = useState([]);
    const { userData } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
              const token = localStorage.getItem('authToken');
              const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/userinfo?username=${userData.username}`, {
                  headers: {
                    "Authorization": `Bearer ${token}`
                  },
              });
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setUserInfo(data.user);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserInfo();
    }, [userData.username]);

    return (
        <Container>
            <LeftSection>
                <h2>User Details</h2>
                <UserDetailsTable user={userInfo} />
                <h2>User Information</h2>
                <UserInfoTable user={userInfo} />
            </LeftSection>

            <RightSection>
                <h2>Account Actions</h2>
                <ActionButton onClick={() => navigate('/profile/edit')}>Update Account Details</ActionButton>
                <ActionButton onClick={() => navigate('/profile/edit/password')}>Change Password</ActionButton>
                <ActionButton onClick={() => navigate('/profile/edit/pin')}>Change User Pin</ActionButton>
                <ActionButton onClick={() => navigate('/profile/edit/email')}>Change Email</ActionButton>
            </RightSection>
        </Container>
    );
};

export default UserDetailsPage;
