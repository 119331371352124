import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getAdminTypeName } from '../utils/AdminTypeUtitls.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import AdminDropdownTextbox from '../components/AdminDropdownTextbox';
import { UserContext } from '../components/UserContext'; // Adjust the import as necessary

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const FormWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
  width: 100%;
  max-width: 600px;
`;

const FormTitle = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 96%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Button = styled.button`
  padding: 12px 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #27ae60;
  }
`;

const BackButton = styled.button`
  padding: 12px 20px;
  background-color: #c9302c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #e74c3c;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%; /* Make the wrapper full width */
`;


const LabelStyled = styled.label`
  margin-bottom: 10px;
  color: white;
`;



// AddDownloadPage Component
const AddAdminPage = () => {
  const navigate = useNavigate();
  
  // State for form fields
  const [name, setName] = useState('');
  const [version, setVersion] = useState('');
  const [link, setLink] = useState('');
  const [categoryId, setCategoryId] = useState(''); 
  const [serverId, setServerId] = useState(''); 
  const [categoryList, setCategoryList] = useState([]);
  const [serverList, setServerList] = useState([]);
  const [activeStatus, setActiveStatus] = useState(''); // Default to active (1)
  const [serverName, setServerName] = useState('');
  const [selectedUsername, setSelectedUsername] = useState('');
  const [usernames, setUsernames] = useState([]);
  const { userData, setUserData } = useContext(UserContext);


   // Simulate fetching categories
   const serverCategories = [20, 30].map((id) => ({
    id,
    name: getAdminTypeName(id),
  }));

  const fetchUsernames = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/usernames`, {
        headers: {
          "Authorization": `Bearer ${token}` // Include API key only
        },
      });
      const data = await response.json();
  
      // Extract usernames from the objects in the array
      if (Array.isArray(data.usernames)) { 
        const extractedUsernames = data.usernames.map(item => item.username); // Extract the username property
        setUsernames(extractedUsernames); // Update the state with the array of strings
      } else {
        console.error('Usernames is not an array:', data.usernames);
      }
    } catch (error) {
      console.error('Error fetching usernames:', error);
    }
  };

  useEffect(() => {
    fetchUsernames();
    setServerList(serverCategories);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
        const newItem = {
            username: selectedUsername,
            type:  serverId,
            adminname: userData.username,
    };
    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/admin/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}` // Include API key only
        },
        body: JSON.stringify(newItem),
      });

      if (!response.ok) {
        throw new Error('Failed to add admin');
      }
      alert('Admin added successfully');
      navigate('/admin/panel'); // Redirect to the download list page
    } catch (error) {
      console.error('Error adding Admin:', error);
    }
  };

  const handleUsernameSelect = (username) => {
    setSelectedUsername(username);
};

  return (
    <PageContainer>
      <FormWrapper>
        <FormTitle>Add Admin</FormTitle>
        <form onSubmit={handleSubmit}>
          <DropdownWrapper>
                <LabelStyled>Username</LabelStyled>
                <AdminDropdownTextbox usernames={usernames} onUsernameSelect={handleUsernameSelect} />
          </DropdownWrapper>

            <tr style={{ height: '20px' }} />
             {/* Server Dropdown */}
          

          <DropdownWrapper>
                <LabelStyled>Type</LabelStyled>
                <Select
                    value={serverId}
                    onChange={(e) => {
                    const selectedServerId = e.target.value;
                    const selectedServer = serverList.find(server => server.id === Number(selectedServerId));
                    setServerId(selectedServerId);
                    setServerName(selectedServer ? selectedServer.name : '');
                    }}
                    required
                >
                    
                    {serverList.map((server) => (
                    <option key={server.id} value={server.id}>
                        {server.name}
                    </option>
                    ))}
            </Select>
            </DropdownWrapper>

          {/* Button Container */}
          <ButtonContainer>
            <BackButton 
                type="button" // This prevents it from being treated as a submit button
                onClick={() => {
                    navigate(-1); // Navigate back to the previous page
                     // Refresh the previous page
                }}
            >
                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
                Back
            </BackButton>
            <Button type="submit">Add Admin</Button>
        </ButtonContainer>
        </form>
      </FormWrapper>
    </PageContainer>
  );
};

export default AddAdminPage;
