import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const PayPalButton = ({ amount, kbpoint, userid, username = "Guest" }) => {
  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID || "your-fallback-client-id",
        currency: "USD",
        intent: "capture",
        commit: true, // Ensure commit flow to capture payment
      }}
    >
      <PayPalButtons
        style={{ layout: "vertical", color: "blue", shape: "rect" }} 
        fundingSource="paypal"
        createOrder={async (data, actions) => {
          try {
            // Ensure amount is valid before proceeding
            if (!amount || amount <= 0) {
              throw new Error("Invalid payment amount");
            }
            const token = localStorage.getItem('authToken');
            // Call your backend to create the order
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/create-order`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
              body: JSON.stringify({ amount }), // Send the amount to the backend
            });
        
            // Handle non-OK responses from the server
            if (!response.ok) {
              const errorDetails = await response.json();
              throw new Error(`Failed to create order: ${errorDetails.message}`);
            }
        
            const order = await response.json(); // Get the order ID from the backend response
        
            return order.id; // Return the order ID to PayPal
        
          } catch (error) {
            console.error("Error during createOrder:", error);
            alert("There was an issue creating the PayPal order. Please try again.");
            return ""; // Return an empty string to indicate failure
          }
        }}
        onApprove={async (data, actions) => {
          try {
            const details = await actions.order.capture(); // Capture the payment
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/transact`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
              body: JSON.stringify({
                transaction_id: details.id,
                order_id: details.id,
                id: userid,
                username: username,
                payment_type: "Paypal",
                payment_method: "PP",
                transaction_status: "settlement",
                kbpoint: kbpoint,
                point_status: "yes.png",
                referralname: "no-ref",
                afiliator: "NO-AFIL",
                price: amount,
                pricetot: amount,
                reward: 1,
                sumprice: 0,
                proofimage: "",
                reference: "",
                timeproof: "",
                kbpoint_num: kbpoint,
                staff_name: "",
              }),
            });
        
            if (!response.ok) {
              const errorDetails = await response.json();
              throw new Error(`Transaction registration failed: ${errorDetails.message}`);
            }
        
            const result = await response.json();
        
            // Only show the alert once everything is successful
            alert(`Transaction completed by ${username}. Ref No: ${details.id}`);
          } catch (err) {
            console.error("Error registering transaction:", err);
            alert("There was an error processing your payment.");
          }
        }}
        onError={(err) => {
          console.error("PayPal Checkout onError", err);
          alert("An error occurred during the PayPal transaction.");
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
