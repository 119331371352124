import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';


// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`;

const RankingContainer = styled.div`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
`;

const RankingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const TableData = styled.td`
  padding: 10px;
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 12px 20px;
  margin: 0 8px;
  background-color: ${(props) => (props.active ? '#2c3e50' : '#bdc3c7')};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #2c3e50;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #7f8c8d;
  }
`;

const Ellipsis = styled.span`
  padding: 10px;
  color: white;
`;

// Button styling
export const SubmitButton = styled.button`
  width: 40%;
  display: inline-block;
  padding: 10px 10px;
  font-size: 16px;
  color: white;
  background-color: red; /* Blue background */
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0392b; /* Darker blue on hover */
  }
`;


// Main component
const RechargeLog = () => {
    const [rechargeLog, setRechargeLog] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Number of items per page
    const [userData, setUserData] = useState({});
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        if (storedData) {
        setUserData(storedData);
        }
    }, []);  // This runs only on component mount to set userData

    useEffect(() => {
        if (userData && userData.username) {  // Only run when userData is set
            const fetchRechargeLog = async () => {
                try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/rechargelog?username=${userData.username}`, {
                  headers: {
                    "Authorization": `Bearer ${token}`
                  },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setRechargeLog(data.rechargelog || []);  // Ensure it's an array
                } catch (error) {
                console.error('Error fetching recharge log:', error);
                }
            };

            fetchRechargeLog();
        }
    }, [userData]);

    const TableComponent = ({order_id, payment_method, transaction_time, transaction_status, referralname, kbpoint,point_status,price,username }) => {
        const paymentBasePath = '../images/payment/';
        const statusBasePath = '../images/misc/';
        const imagePaymentPath = `${paymentBasePath}${payment_method.toLowerCase()}.png`;
        const imageStatusPath = `${statusBasePath}${transaction_status}.png`;
        const imagePointStatusPath = `${statusBasePath}${point_status}`;
        const kbpointInt = +kbpoint;
        let currencySign = '$';
        if (payment_method === 'Peso') {
          currencySign = '₱';
        } else if (payment_method === 'PP') {
          currencySign = '$';
        }
        
        return (
          <TableRow>
            <TableData><img src={imagePaymentPath} alt="class icon" style={{ height: "20px" }} /></TableData>
            <TableData>{order_id}</TableData>
            <TableData>{formatDate(transaction_time)}</TableData>
            {/* <TableData><img src={imageCampusPath} alt="class icon" style={{ height: "20px" }} /></TableData> */}
            <TableData><img src={imageStatusPath} alt="class icon" style={{ height: "20px" }} /></TableData>
            <TableData>{referralname}</TableData>
            <TableData><img src={imagePointStatusPath} alt="class icon" style={{ height: "20px" }} />{kbpointInt.toLocaleString()}</TableData>
            <SubmitButton type="button" onClick={() => handleProceedToInvoice(order_id, username, kbpoint, price, imagePaymentPath, currencySign,imageStatusPath)}>
            <FontAwesomeIcon icon={faEye} style={{ marginRight: '8px' }} />
                Show
            </SubmitButton>
          </TableRow>
        );
      };

    
    const handleProceedToInvoice = (orderId, id, kbpoint, price, logo, currencySign,imageStatusPath) => {
        navigate('/invoice', { state: { orderId, id, kbpoint, price, logo, currencySign,imageStatusPath } });
    };

  // Get current page rankings
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRechargeLog= rechargeLog.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(rechargeLog.length / itemsPerPage);

  const getPaginationItems = () => {
    const pageNumbers = [];
    const visiblePages = 3;

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);

      if (currentPage > visiblePages + 2) {
        pageNumbers.push('ellipsis-left');
      }

      for (let i = Math.max(2, currentPage - visiblePages); i <= Math.min(totalPages - 1, currentPage + visiblePages); i++) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - visiblePages - 1) {
        pageNumbers.push('ellipsis-right');
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers.filter((item, index, self) =>
      index === self.indexOf(item) && !(item === 'ellipsis-left' && self[index - 1] === 'ellipsis-left') &&
      !(item === 'ellipsis-right' && self[index + 1] === 'ellipsis-right')
    );
  };

  return (
    <PageContainer>
      <ContentWrapper>
        <RankingContainer>
          <Title>Recharge Log</Title>
          <RankingTable>
            <thead>
              <tr>
                <TableHeader>Gate</TableHeader>
                <TableHeader>Invoice</TableHeader>
                <TableHeader>Date</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Ref Code</TableHeader>
                <TableHeader>R-coin</TableHeader>
                <TableHeader>View Invoice</TableHeader>
              </tr>
            </thead>
            <tbody>
              {currentRechargeLog.map((rechargeItem, index) => (
                <TableComponent
                key={rechargeItem.order_id}
                order_id={rechargeItem.order_id}
                payment_method={rechargeItem.payment_method}
                transaction_time={rechargeItem.transaction_time}
                transaction_status={rechargeItem.transaction_status}
                referralname={rechargeItem.referralname}
                kbpoint={rechargeItem.kbpoint}
                point_status={rechargeItem.point_status}
                price={rechargeItem.price}
                username = {userData.username}
                />
              ))}
            </tbody>
          </RankingTable>

          <Pagination>
            <PageButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </PageButton>

            {getPaginationItems().map((pageNumber, index) => {
              if (pageNumber === 'ellipsis-left' || pageNumber === 'ellipsis-right') {
                return <Ellipsis key={index}>...</Ellipsis>;
              }

              return (
                <PageButton
                  key={pageNumber}
                  active={currentPage === pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </PageButton>
              );
            })}

            <PageButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </PageButton>
          </Pagination>
        </RankingContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

const formatDate = (dateString) => {
  // Create a Date object from the UTC date string
  const date = new Date(dateString);

  // Get the year, month, and day in UTC
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');

  // Get the hours, minutes, and seconds in UTC
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Return in YYYY-MM-DD HH:MM:SS format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export default RechargeLog;
