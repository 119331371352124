import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { getCategoryName } from '../utils/CategoryUtils.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const FormWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
  width: 100%;
  max-width: 600px;
`;

const FormTitle = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 96%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Button = styled.button`
  padding: 12px 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #27ae60;
  }
`;

const BackButton = styled.button.attrs(() => ({ type: 'button' }))`
  padding: 12px 20px;
  background-color: #c9302c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #e74c3c;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

// UpdateItemPage Component
const UpdateItemPage = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the item ID from the URL params
  const [itemName, setItemName] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [itemStock, setItemStock] = useState('');
  const [itemMain, setItemMain] = useState('');
  const [itemSub, setItemSub] = useState('');
  const [itemDisc, setItemDisc] = useState('');
  const [category, setCategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [activeStatus, setActiveStatus] = useState('0');

  useEffect(() => {
    // Fetch existing item details based on itemId
    const fetchItemDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/shopitemmap/${id}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          },
        });
        if (!response.ok) throw new Error('Failed to fetch item details');
        const itemData = await response.json();
        
        // Set the state with the fetched item data
        setItemName(itemData.item.itemname);
        setItemPrice(itemData.item.itemprice);
        setItemStock(itemData.item.itemstock);
        setItemMain(itemData.item.itemmain.toString());
        setItemSub(itemData.item.itemsub.toString());
        setItemDisc(itemData.item.itemdisc);
        setCategory(itemData.item.category);
        setActiveStatus(itemData.item.hidden.toString()); // Assuming hidden is 0 or 1
      } catch (error) {
        console.error('Error fetching item details:', error);
      }
    };

    // Simulate fetching categories
    const categories = [1, 2, 3, 4, 5, 6].map((id) => ({
      id,
      name: getCategoryName(id),
    }));
    setCategoryList(categories);
    
    fetchItemDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedItem = {
      itemname: itemName,
      itemprice: itemPrice,
      itemstock: itemStock,
      itemdisc: itemDisc,
      itemmain: itemMain,
      itemsub: itemSub,
      category: Number(category),
      hidden: Number(activeStatus),
    };

    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/shopitemmap/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(updatedItem),
      });

      if (!response.ok) {
        throw new Error('Failed to update item');
      }
      alert("Item updated successfully");
      navigate('/shop/panel'); // Redirect to the item list page
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  return (
    <PageContainer>
      <FormWrapper>
        <FormTitle>Update Item</FormTitle>
        <form onSubmit={handleSubmit}>
        
        <Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="">Select Category</option>
            {categoryList.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </Select>

          {/* Active Status Dropdown */}
          <Select
            value={activeStatus}
            onChange={(e) => setActiveStatus(e.target.value)}
            required
          >
            <option value="0">Active</option>
            <option value="1">Hidden</option>
          </Select>

          <Input
            type="text"
            placeholder="Item Name"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            required
          />
           <Input
            type="text"
            placeholder="Item Main"
            value={itemMain}
            onChange={(e) => setItemMain(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Item Sub"
            value={itemSub}
            onChange={(e) => setItemSub(e.target.value)}
          />
          <Input
            type="number"
            placeholder="Item Price"
            value={itemPrice}
            onChange={(e) => setItemPrice(e.target.value)}
            required
          />
          <Input
            type="number"
            placeholder="Item Stock"
            value={itemStock}
            onChange={(e) => setItemStock(e.target.value)}
            required
          />
          <Input
            type="number"
            placeholder="Item Discount (%)"
            value={itemDisc}
            onChange={(e) => setItemDisc(e.target.value)}
          />
          
          <ButtonContainer>
            <BackButton 
                type="button" // This prevents it from being treated as a submit button
                onClick={() => {
                    navigate(-1); // Navigate back to the previous page
                }}
            >
                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
                Back
            </BackButton>
            <Button type="submit">Update Item</Button>
        </ButtonContainer>
        </form>
      </FormWrapper>
    </PageContainer>
  );
};

export default UpdateItemPage;
