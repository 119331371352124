import { useLocation , useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';


// Styled Font Awesome icon with warning color
export const FaRegistered = styled.i`
  color: #f39c12; /* Warning color */
`;

// Styled-components for layout and styling
const InvoiceContainer = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  // background-color: rgba(0,0,0,0.7);
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 1%;
`;

const SectionTitle = styled.h1`
  text-align: center;
  color: black;
`;

const InvoiceDetails = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  // background-color: rgba(0,0,0,0.7);
  background-color: #f0f3f4;
  
`;

const DetailItem = styled.h3`
  margin: 10px 0;
  font-size: 16px;
`;

const DetailParagraph = styled.p`
  margin: 5px 0;
  font-size: 16px;
`;

const Logo = styled.img`
  width: 50px;
  height: auto;
  margin: 10px auto;
  display: block; /* Center the image */
`;

const GcashDetails = styled.div`
  text-align: center; /* Center the text */
  margin-top: 10px;
  color: black;
  font-size: 14px;
`;

// Horizontal line styling
const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: #ddd;
`;

// Styled-components for label-value pairs
const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.h3`
  font-size: 16px;
  color: black;
`;

const Value = styled.p`
  font-size: 16px;
  color: black;
  margin-left: 10px;
`;

const CouponCodeContainer = styled.div`
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black; /* Line border */
  color: #333; /* Dark gray for better readability */
  font-size: 18px;
  text-align: center;
  border-radius: 5px;
  max-width: 90%;
  margin: 20px auto; /* Center the container */
  background-color: white; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
`;

// Modal background styling
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Modal content styling
const ModalContent = styled.div`
  background-color: #fff; /* White background for contrast */
  padding: 30px; /* Increased padding for spaciousness */
  border-radius: 12px; /* Softer border radius */
  max-width: 450px; /* Adjust the modal width */
  text-align: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Larger shadow for depth */
  position: relative;
`;

// Close button styling
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

// Question mark icon styling
const QuestionMarkIcon = styled(FaQuestionCircle)`
  color: #f39c12;
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    color: #e67e22;
  }
`;

// Styled buttons
const Button = styled.button`
  margin: 20px;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #2980b9;
  }
`;

// Styled buttons
const CancelButton = styled.button`
   margin: 20px;
  padding: 10px 20px;
  background-color: red;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #c0392b;
  }
`;

// Modal content styling for QuestionMarkIcon modal
const QuestionModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
`;

// Modal background styling for QuestionMarkIcon modal
const QuestionModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; /* Higher z-index for question icon modal */
`;


// Modal background styling for confirm transfer
const ConfirmTransferModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Slightly lower than question mark modal */
`;

const ConfirmTransferModalContent = styled.div`
  background-color: #ffffff;
  padding: 40px 30px;
  border-radius: 12px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  z-index: 1100;

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 30px 20px;
  }

  h3 {
    color: #2c3e50;
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    color: #7f8c8d;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.6;
  }

  .info-section {
    background-color: #f0f3f4;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    text-align: left;
    color: #2c3e50;
    font-size: 16px;
  }

  .info-row {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .info-label {
    font-weight: bold;
  }

  input {
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .question-icon {
    display: inline-block;
    margin-left: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #f39c12;
    &:hover {
      color: #e67e22;
    }
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
    color: #ffffff;
    background-color: #27ae60;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
    &:hover {
      background-color: #229954;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

// Input styling for the reference number input
const RefNumberInput = styled.input`
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
`;

// Confirm button styling
const ConfirmButton = styled(Button)`
  background-color: #27ae60;
  &:hover {
    background-color: #229954;
  }
`;

const PaymentMethod = styled.div`
  display: flex;
  align-items: center;  /* Vertically center the image and currency */
  margin-left: auto;    /* Push this section to the right */
`;

const InvoicePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { orderId, id, kbpoint, price, logo, currencySign, imageStatusPath } = location.state || {};
    const [userData, setUserData] = useState({});
    const [gcashAcc, setGcashAcc] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
    const [isConfirmTransferModalOpen, setIsConfirmTransferModalOpen] = useState(false); // Modal state for transfer confirmation
    const [refNumber, setRefNumber] = useState(''); // Reference number state
    

     // Function to toggle modal
    const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
    };

    const toggleConfirmTransferModal = () => {
      setIsConfirmTransferModalOpen(!isConfirmTransferModalOpen);
    };

    const handleBackClick = () => {
      navigate('/recharge/log'); // Go back to the previous page
    };

    // Function for "Confirm Transfer" button click
    const handleConfirmTransferClick = () => {
      setIsModalOpen(true);  // Open the reference number modal (toggleModal)
    };

    const handleConfirmTransactionClick = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/updatetransaction`, {
          method: "PUT", // Use PUT for updating the transaction
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}` // Include API key only
          },
          body: JSON.stringify({
            orderId, // Pass the value directly, no need for {}
            refCode: refNumber // Pass the value directly
          }),
        });
    
        if (response.ok) {
          alert('Please allow 5-10 minutes to process your confirmation');
          navigate('/recharge/log');
        } else {
          const data = await response.json();
          if (data.error) {
            // Display the error if the refCode already exists
            console.error(data.error);
            alert(data.error); // or display this error in the UI using state
          }
        }
      } catch (err) {
        console.error("Error updating transaction:", err);
      }
    };

    // Function to close toggleModal and open the toggleConfirmTransferModal
    const handleCloseModalAndOpenConfirmTransfer = () => {
      setIsModalOpen(false);  // Close the reference number modal
      setIsConfirmTransferModalOpen(true);  // Open the confirm transfer modal
    };

    // Define your base path for images
    const gcashBasePath = '../images/payment/'; // Adjust the path according to your folder structure
  
    // Generate the complete image path
    const imageGcashPath = `${gcashBasePath}gcash-qr.jpg`;
  
    useEffect(() => {
      const storedData = JSON.parse(localStorage.getItem('userData'));
      if (storedData) {
        setUserData(storedData);
      }
  
      const fetchGcashAccount = async () => {
        try {
          const token = localStorage.getItem('authToken');
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/gcashacc`, {
            headers: {
              "Authorization": `Bearer ${token}` // Include API key only
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setGcashAcc(data.gcashacc);
        } catch (error) {
          console.error('Error fetching news:', error);
        }
      };
      fetchGcashAccount();
    }, []);
  
    // Assuming the G-Cash reference number comes in the `gcashAcc.detail`
    const gcashRefNumber = gcashAcc.detail || ''; 
    const lastSixDigits = gcashRefNumber.slice(-6); // Extract the last 6 digits

    const kbpointInt = +kbpoint;
    const priceInt = +price;
  
    return (
      <InvoiceContainer>
        <SectionTitle>Invoice</SectionTitle>
        <InvoiceDetails>
          <DetailItem>Invoice Details</DetailItem>
          <Divider />
  
          <DetailRow>
            <Label>UserID:</Label>
            <Value>{userData.username}</Value>
          </DetailRow>
  
          <DetailRow>
            <Label>Email:</Label>
            <Value>{userData.UserEmail}</Value>
          </DetailRow>
  
          <Divider />
  
          <DetailRow>
            <Label>Invoice:</Label>
            <Value>{orderId}</Value>
          </DetailRow>
  
          <Divider />
  
          <DetailRow>
            <Label>Amount Paid:</Label>
            <Value><strong style={{ fontSize: '50px', color:'red' }}>{currencySign} {priceInt.toLocaleString()}</strong></Value>
          </DetailRow>
  
          <Divider />
  
          <DetailRow>
            <Label>R-coin:</Label>
            <Value><FaRegistered className="fas fa-registered" /> {kbpointInt.toLocaleString()}</Value>
          </DetailRow>
  
          <DetailRow>
            <Label>Ref (no-ref):</Label>
            <Value>+0%</Value>
          </DetailRow>
  
          <DetailRow>
            <Label>Total R-coin:</Label>
            <Value><FaRegistered className="fas fa-registered" /> {kbpointInt.toLocaleString()}</Value>
          </DetailRow>
  
          <Divider />
          
          
          {imageStatusPath ? (
            <>
            <DetailRow>
              <Label>Payment method:</Label>
              <PaymentMethod>
                <img src={logo} alt="class icon" style={{ height: "20px", marginRight: "4px" }} />
                <span>{currencySign === '$' ? 'Paypal' : currencySign === '₱' ? 'GCASH' : ''}</span>
              </PaymentMethod>
            </DetailRow>
            <DetailRow>
              <Label>Payment status:</Label>
              <img src={imageStatusPath} alt="class icon" style={{ height: "20px" }} />
            </DetailRow>
            </>
          ) : (
            <>
              <Logo src={imageGcashPath} alt="logo" style={{ height: "150px", width: "150px" }} />
              <GcashDetails>
                <p>{gcashAcc.status}</p>
                <p>{gcashAcc.detail}</p>
              </GcashDetails>
            </>
          )}
          
        
        {isModalOpen && (
          <QuestionModalBackground>
            <QuestionModalContent>
              <CloseButton onClick={handleCloseModalAndOpenConfirmTransfer}>×</CloseButton>
              
              <h3>Where to Find Your GCash Reference Number</h3>
              <p>
                After completing your payment, review your GCash receipt and locate the reference number{' '}
                <strong style={{ color:'red' }}>(Ref No.)</strong> as shown below.
              </p>
              <Logo
                src="../images/payment/gcash-reference-sample.jpg"
                alt="GCash Reference Number Example"
                style={{ height: '80%', width: '80%' }}
              />
              <CouponCodeContainer>
              YOUR  <strong style={{ color:'red' }}>LAST 6 DIGIT</strong> OF G-CASH REFERENCE NUMBER IS YOUR COUPON CODE.
              </CouponCodeContainer>
            </QuestionModalContent>
          </QuestionModalBackground>
        )}
        </InvoiceDetails>

         {/* Transfer Modal */}
         {isConfirmTransferModalOpen && (
          <ConfirmTransferModalBackground>
            <ConfirmTransferModalContent>
              <CloseButton onClick={toggleConfirmTransferModal}>×</CloseButton>
              <h3>Confirm Transfer</h3>
             {/* Section for invoice and user info */}
              <div className="info-section">
                <div className="info-row">
                  <span className="info-label">Invoice Number:</span>
                  <span>{orderId}</span>
                </div>
                <div className="info-row">
                  <span className="info-label">Username:</span>
                  <span>{userData.username}</span>
                </div>
              </div>
              
               {/* Input for GCash reference number */}
              <p>Enter GCash Reference Number: <FaQuestionCircle className="question-icon" onClick={toggleModal} /></p>
              <input
                type="number"
                value={refNumber}
                onChange={(e) => setRefNumber(e.target.value)}
                placeholder="Reference Number"
                minLength={6}
                maxLength={6}
              />
              {refNumber.length > 0 && refNumber.length !== 6 && (
                <p style={{ color: 'red' }}>Reference number must be 6 digits</p>
              )}

              <ConfirmButton onClick={handleConfirmTransactionClick}>Confirm Transaction</ConfirmButton>
            </ConfirmTransferModalContent>
          </ConfirmTransferModalBackground>
        )}
  
        <ButtonContainer>
          {/* Back and Confirm Transfer Buttons */}
          <CancelButton onClick={handleBackClick}>Back to Log</CancelButton>
          

          {imageStatusPath !== '../images/misc/deny.png' && (<Button onClick={handleConfirmTransferClick}>Confirm Transfer</Button>) }
        </ButtonContainer>
  
      </InvoiceContainer>
    );
  };

export default InvoicePage;
