import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import AdminDropdownTextbox from '../components/AdminDropdownTextbox';
import { UserContext } from '../components/UserContext'; // Adjust the import as necessary
import { getCategoryName , getType } from '../utils/CouponUtils.js';
import CouponDropdownTextbox from '../components/CouponDropdownTextbox.js';
import CouponCodeDropdownTextbox from '../components/CouponCodeDropdownTextbox.js';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const FormWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
  width: 100%;
  max-width: 600px;
`;

const FormTitle = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 96%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Button = styled.button`
  padding: 12px 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #27ae60;
  }
`;

const BackButton = styled.button`
  padding: 12px 20px;
  background-color: #c9302c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #e74c3c;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%; /* Make the wrapper full width */
`;


const LabelStyled = styled.label`
  margin-bottom: 10px;
  color: white;
`;



// AddDownloadPage Component
const AddCouponPage = () => {
  const navigate = useNavigate();
  
  // State for form fields
  const [code, setCode] =  useState([]);
  const [selectedUsername, setSelectedUsername] = useState('');
  const { userData, setUserData } = useContext(UserContext);
  const [categoryList, setCategories] = useState([]);
  const [typeList, setType] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [typeId, setTypeId] = useState('');
  const [typeName, setTypeName] = useState('');
  const [amount, setAmount] = useState('');
  const [itemNames, setItemNames] = useState([]);
  const [selectedProductNum, setSelectedProductNum] = useState('');
  const [selectedItemName, setSelectedItemName] = useState('');
  const [selectedCodeName, setSelectedCodeName] = useState('');
  

   // Simulate fetching categories
   const serverCategories = [1, 2, 3].map((id) => ({
    id,
    name: getCategoryName(id),
  }));

    const serverType = [1, 2].map((id) => ({
        id,
        name: getType(id),
    }));

    const fetchItems = async () => {
        try {
          const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/itemnames`, {
              headers: {
                "Authorization": `Bearer ${token}` // Include API key only
              },
            });
            const data = await response.json();
    
            // Check if itemnames is an array and extract item names and product numbers
            if (Array.isArray(data.itemnames)) {
                const extractedItems = data.itemnames.map(item => ({
                    productnum: item.productnum,
                    itemname: item.itemname
                })); // Create an array of objects
                setItemNames(extractedItems); // Update the state with the array of objects
            } else {
                console.error('Itemnames is not an array:', data.itemnames);
            }
        } catch (error) {
            console.error('Error fetching item names:', error);
        }
    };

    const fetchReferenceCode = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/pendingreferencecode`, {
          headers: {
            "Authorization": `Bearer ${token}`
          },
        });
    
        // Check if the response is okay (status in the range 200-299)
        if (!response.ok) {
          const errorText = await response.text(); // Get the raw response text
          console.error('Error fetching reference code:', errorText);
          throw new Error(`HTTP error! status: ${response.status}`); // Throw an error to be caught below
        }
    
        const data = await response.json();
    
        // Check if pendingreferencecode is an array and extract item names and product numbers
        if (Array.isArray(data.pendingreferencecode)) {
          const extractedItems = data.pendingreferencecode.map(item => ({
            reference: item.reference,
            kbpoint: item.kbpoint,
            username: item.username,
          }));
          setCode(extractedItems); // Update the state with the array of objects
        } else {
          console.error('pendingreferencecode is not an array:', data.pendingreferencecode);
        }
      } catch (error) {
        console.error('Error fetching reference code:', error);
      }
    };

  useEffect(() => {
    fetchItems();
    fetchReferenceCode();
    setCategories(serverCategories); // Ensure it's setting an array
    setType(serverType);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newItem = {
        code: selectedCodeName,
        kbpoint: amount,
        productnum: selectedProductNum,
        type:  typeId,
        category: categoryId,
        adminname: userData.username,
        kbuser: selectedUsername,
    };

    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/coupon/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}` // Include API key only
        },
        body: JSON.stringify(newItem),
      });

      if (!response.ok) {
        throw new Error('Failed to add admin');
      }

      setUserData((prevData) => ({
        ...prevData,
        kbpoint: +prevData.kbpoint + +amount,
      }));
      alert('Coupon added successfully');
      navigate('/coupon/panel'); // Redirect to the download list page
    } catch (error) {
      console.error('Error adding Admin:', error);
    }
  };
  
  
  return (
    <PageContainer>
      <FormWrapper>
        <FormTitle>Add Coupon</FormTitle>
        <form onSubmit={handleSubmit}>

        <DropdownWrapper>
            <Select
                value={typeId}
                onChange={(e) => {
                    const selectedTypeId = e.target.value;
                    const selectedType = typeList.find(type => type.id === Number(selectedTypeId));
                    setTypeId(selectedTypeId);
                    setTypeName(selectedType ? selectedType.name : '');
                }}
                required
            >
                <option value="">Select Type</option> {/* Default prompt */}
                {typeList.map((type) => (
                    <option key={type.id} value={type.id}>
                        {type.name}
                    </option>
                ))}
            </Select>
        </DropdownWrapper>

        <DropdownWrapper>
            <Select
                value={categoryId}
                onChange={(e) => {
                    const selectedCategoryId = e.target.value;
                    const selectedCategory = categoryList.find(category => category.id === Number(selectedCategoryId));
                    setCategoryId(selectedCategoryId);
                    setCategoryName(selectedCategory ? selectedCategory.name : '');
                }}
                required
            >
                <option value="">Select Category</option> {/* Default prompt */}
                {categoryList.map((category) => (
                    <option key={category.id} value={category.id}>
                        {category.name}
                    </option>
                ))}
            </Select>
        </DropdownWrapper>

        {categoryId !== '2' && ( <>
            <DropdownWrapper>
              <CouponDropdownTextbox 
                items={itemNames}
                onSelect={(selectedItem) => {
                  setSelectedItemName(selectedItem.itemname);
                  setSelectedProductNum(selectedItem.productnum);
                }} 
              />
            </DropdownWrapper>
            <tr style={{ height: '20px' }} />
            </>
          )}

            <DropdownWrapper>
              <CouponCodeDropdownTextbox 
                items={code}
                onSelect={(selectedItem) => {
                  setSelectedCodeName(selectedItem.reference);
                  setAmount(selectedItem.kbpoint);
                  setSelectedUsername(selectedItem.username);
                }} 
              />
            </DropdownWrapper>
            
            <tr style={{ height: '20px' }} />
            <Input
            type="text"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />

           

          {/* Button Container */}
          <ButtonContainer>
            <BackButton 
                type="button" // This prevents it from being treated as a submit button
                onClick={() => {
                    navigate(-1); // Navigate back to the previous page
                     // Refresh the previous page
                }}
            >
                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
                Back
            </BackButton>
            <Button type="submit">Add Coupon</Button>
        </ButtonContainer>
        </form>
      </FormWrapper>
    </PageContainer>
  );
};

export default AddCouponPage;
