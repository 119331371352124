import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom"; // Import useNavigate


const FormContainer = styled.div`
  width: 40%;
  background-color: #1c1c1c; /* Darker background */
  margin: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 5px 5px 0 rgba(0, 0, 0, 0.3); /* Adjusted shadow */
  padding: 10px;
  margin-top: 100px;
`;

const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const SuccessMessage = styled.div`
  font-family: "Roboto", sans-serif;
  background-color: #2e7d32; /* Greenish background for success */
  padding: 15px;
  color: white;
  text-align: center;
`;

const FormField = styled.input`
  width: 90%; /* Full width of container */
  margin: 0; /* Remove default margin */
  padding: 15px;
  font-size: 16px;
  border: 1px solid #444; /* Dark border for input */
  border-radius: 4px; /* Rounded corners */
  font-family: "Roboto", sans-serif;
  background: #333; /* Darker background for input */
  color: #eee; /* Lighter text color for input */

  &::placeholder {
    color: grey; /* Grey placeholder text */
  }

  &.error {
    border-color: #ff6f61; /* Red border for errors */
  }
`;
const ErrorSpan = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #ff6f61; /* Red color for error messages */
  margin: 5px 0; /* Margin for spacing between field and error */
`;

const SubmitButton = styled.button`
  margin-top: 20px;
  background: #273c75; /* Teal background for button */
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 4px; /* Rounded corners */
  font-family: "Roboto", sans-serif;

  &:disabled {
    cursor: not-allowed;
    background: #004d40; /* Darker teal for disabled state */
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px; /* Space between fields */
  width: 100%;
`;

const SideBySideGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px; /* Space between fields */
  width: 100%;
`;

const FormFieldContainer = styled.div`
  width: 100%; /* Full width of container */
  margin-bottom: 20px; /* Space below each field */
`;




const Register = React.memo(() => {
  const [values, setValues] = useState({
    fullName: "",
    password: "",
    confirmPassword: "",
    pincode: "",
    confirmPincode: "",
    birthday: ""
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const [serverError, setServerError] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const navigate = useNavigate();

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setValues((values) => ({
      ...values,
      [name]: value
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(values);
  
    if (Object.keys(validationErrors).length === 0 ) {
      setValid(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/registeruser`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values.email,
            userId: values.userId,
            password: values.password,
            pincode: values.pincode,
            birthday: values.birthday,
            fullname: values.fullName,
          }),
        });
  
        const data = await response.json(); // Parse the response data
  
        if (response.ok) {
          setSubmitted(true);
          setServerError(""); // Clear any previous errors
          setSuccessMessage(`Welcome ${values.fullName}!
          Your registration was successful!`); // Set success message
          // You can show a success message here if needed
        } else {
          // Set the error message from the server response
          setServerError(data.error || "Registration failed");
          setSuccessMessage(""); // Clear success message on error
        }
      } catch (err) {
        console.error("Error registering user:", err);
        setServerError("Registration failed");
        setSuccessMessage(""); // Clear success message on error
      }
    } else {
      setValid(false);
    }
  
    setErrors(validationErrors); // Set validation errors if any
    setSubmitted(true);
  };

  const validateForm = (values) => {
    const errors = {};

    const minAgeDate = new Date();
    minAgeDate.setFullYear(minAgeDate.getFullYear() - 10);


    if (!values.fullName) errors.fullName = "Please enter your full name";
    if (!values.email) errors.email = "Please enter your email";
    if (!values.userId) errors.userId = "Please enter a user ID";
    if (!values.password) errors.password = "Please enter a password";
    if (values.password !== values.confirmPassword) errors.confirmPassword = "Passwords do not match";
    if (!values.pincode) errors.pincode = "Please enter a pincode";
    if (values.pincode !== values.confirmPincode) errors.confirmPincode = "Pincodes do not match";
    if (!values.birthday) {
      errors.birthday = "Please enter your birthday";
    } else {
      const birthdayDate = new Date(values.birthday);
      if (birthdayDate > minAgeDate) {
        errors.birthday = "You must be older than 10 years";
      }
    }
    
    return errors;
  };

  return (
    <FormContainer>
      <RegisterForm onSubmit={handleSubmit}>
      {successMessage && (
          <SuccessMessage>{successMessage}</SuccessMessage> // Display success message
        )}
            <SideBySideGroup>
              <FormFieldContainer>
                <FormField
                  type="text"
                  placeholder="Full Name"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleInputChange}
                  className={errors.fullName ? "error" : ""}
                />
                {errors.fullName && (
                  <ErrorSpan id="full-name-error">{errors.fullName}</ErrorSpan>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <FormField
                  type="date"
                  placeholder="Birthday"
                  name="birthday"
                  value={values.birthday}
                  onChange={handleInputChange}
                  className={errors.birthday ? "error" : ""}
                />
                {errors.birthday && (
                  <ErrorSpan id="birthday-error">{errors.birthday}</ErrorSpan>
                )}
              </FormFieldContainer>
            </SideBySideGroup>
            <SideBySideGroup>
                <FormFieldContainer>
                <FormField
                  type="text"
                  placeholder="User ID"
                  name="userId"
                  value={values.userId}
                  onChange={handleInputChange}
                  className={errors.userId ? "error" : ""}
                />
                {errors.userId && (
                  <ErrorSpan id="user-id-error">{errors.userId}</ErrorSpan>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <FormField
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  className={errors.email ? "error" : ""}
                />
                {errors.email && (
                  <ErrorSpan id="email-error">{errors.email}</ErrorSpan>
                )}
              </FormFieldContainer>
              
            </SideBySideGroup>
            <FieldGroup>
              <FormFieldContainer>
                <FormField
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleInputChange}
                  className={errors.password ? "error" : ""}
                />
                {errors.password && (
                  <ErrorSpan id="password-error">{errors.password}</ErrorSpan>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <FormField
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleInputChange}
                  className={errors.confirmPassword ? "error" : ""}
                />
                {errors.confirmPassword && (
                  <ErrorSpan id="confirm-password-error">{errors.confirmPassword}</ErrorSpan>
                )}
              </FormFieldContainer>
            </FieldGroup>
            <FieldGroup>
              <FormFieldContainer>
                <FormField
                  type="text"
                  placeholder="Pincode"
                  name="pincode"
                  value={values.pincode}
                  onChange={handleInputChange}
                  className={errors.pincode ? "error" : ""}
                />
                {errors.pincode && (
                  <ErrorSpan id="pincode-error">{errors.pincode}</ErrorSpan>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <FormField
                  type="text"
                  placeholder="Confirm Pincode"
                  name="confirmPincode"
                  value={values.confirmPincode}
                  onChange={handleInputChange}
                  className={errors.confirmPincode ? "error" : ""}
                />
                {errors.confirmPincode && (
                  <ErrorSpan id="confirm-pincode-error">{errors.confirmPincode}</ErrorSpan>
                )}
              </FormFieldContainer>
            </FieldGroup>
            {serverError && (
              <ErrorSpan>{serverError}</ErrorSpan> // Display server error message
            )}
            <SubmitButton type="submit">
              Register
            </SubmitButton>
      </RegisterForm>
    </FormContainer>
  );
});

export default Register;
