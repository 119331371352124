import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const FormWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
  width: 100%;
  max-width: 600px;
`;

const FormTitle = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 96%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Button = styled.button`
  padding: 12px 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #27ae60;
  }
`;

const BackButton = styled.button`
  padding: 12px 20px;
  background-color: #c9302c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #e74c3c;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

// AddVoucherPage Component
const AddVoucherPage = () => {
  const navigate = useNavigate();

  // State for form fields
  const [voucherData, setVoucherData] = useState({
    gateway: '',
    name: '',
    price: '',
    rcoin: '' // Added R-coin field
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVoucherData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/vouchers/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}` // Include API key only
        },
        body: JSON.stringify(voucherData),
      });

      if (!response.ok) {
        throw new Error('Failed to add voucher');
      }

      alert('Voucher added successfully');
      navigate('/recharge/panel');
    } catch (error) {
      console.error('Error adding voucher:', error);
    }
  };

  return (
    <PageContainer>
      <FormWrapper>
        <FormTitle>Add New Voucher</FormTitle>
        <form onSubmit={handleSubmit}>
          {/* Gateway (Dropdown) */}
          <Select
            name="gateway"
            value={voucherData.gateway}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select Gateway</option>
            <option value="Midtrans">Midtrans</option>
            <option value="Paypal">Paypal</option>
            <option value="Peso">Peso</option>
          </Select>

          {/* Status */}
          <Input
            type="text"
            name="name"
            placeholder="Name"
            value={voucherData.name}
            onChange={handleChange}
            required
          />

          {/* R-coin */}
          <Input
            type="text"
            name="rcoin"
            placeholder="R-coin"
            value={voucherData.rcoin}
            onChange={handleChange}
          />
          
          {/* Detail */}
          <Input
            type="text"
            name="price"
            placeholder="Price"
            value={voucherData.price}
            onChange={handleChange}
          />

          {/* Button Container */}
          <ButtonContainer>
            <BackButton onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
              Back
            </BackButton>
            <Button type="submit">Add Voucher</Button>
          </ButtonContainer>
        </form>
      </FormWrapper>
    </PageContainer>
  );
};

export default AddVoucherPage;
