import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import default quill styles
import { getNewsCategoryName } from '../utils/NewsCategoryUtils.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Add fonts and sizes to the toolbar options
const fontFamilyOptions = [
  { label: 'Sans Serif', value: 'sans-serif' },
  { label: 'Serif', value: 'serif' },
  { label: 'Monospace', value: 'monospace' },
];

// Register custom fonts in Quill
var Font = Quill.import('formats/font');
Font.whitelist = fontFamilyOptions.map(option => option.value); // Only show these fonts
Quill.register(Font, true);

// Custom toolbar options including font styles
const toolbarOptions = [
  [{ 'font': Font.whitelist }],
  [{ 'size': ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'align': [] }],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],
  ['link', 'image'],
  ['clean']  // remove formatting button
];

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const FormWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: white;
  width: 100%;
  max-width: 600px;
`;

const FormTitle = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 96%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #333;
  color: white;
`;

const Button = styled.button`
  padding: 12px 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #27ae60;
  }
`;

const BackButton = styled.button`
  padding: 12px 20px;
  background-color: #c9302c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #e74c3c;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

// Styled wrapper for the editor
const StyledEditorWrapper = styled.div`
  .ql-toolbar {
    background-color: #333; /* Toolbar background */
  }

  .ql-toolbar .ql-picker-label,
  .ql-toolbar .ql-picker-item,
  .ql-toolbar button {
    color: white; /* Make toolbar icons and headings white */
  }

  /* Make dropdowns and their items white */
  .ql-toolbar .ql-picker-options {
    background-color: black; /* Change dropdown background color to black */
    border: 1px solid #fff;
  }

  .ql-toolbar .ql-picker-options .ql-picker-item {
    color: white; /* Dropdown text color */
  }

  .ql-toolbar .ql-picker-options .ql-picker-item:hover {
    background-color: #444; /* Change background on hover */
  }

  /* Styling specific buttons for bold, italic, underline, etc. */
  .ql-toolbar button.ql-bold,
  .ql-toolbar button.ql-italic,
  .ql-toolbar button.ql-underline,
  .ql-toolbar button.ql-strike {
    color: white; /* Make bold, italic, underline, and strike white */
  }

  /* Make header dropdown options white */
  .ql-toolbar .ql-header .ql-picker-label,
  .ql-toolbar .ql-header .ql-picker-item {
    color: white;
  }

  /* Hover effect for all toolbar buttons */
  .ql-toolbar button:hover,
  .ql-toolbar .ql-picker-label:hover,
  .ql-toolbar .ql-picker-item:hover {
    color: #ddd; /* Optional: Hover effect */
  }
`;

// Styled ReactQuill component
const StyledEditor = styled(ReactQuill)`
  .ql-editor {
    background-color: #333;
    color: #fff;
    height: 250px;
    border-radius: 4px;
  }
`;


// AddNewsPage Component
const NewsPanelEdit = () => {
  const navigate = useNavigate();
  
  // State for form fields
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState(''); // Updated to rich text
  const [categoryId, setCategoryId] = useState(''); 
  const [categoryList, setCategoryList] = useState([]);
  const [activeStatus, setActiveStatus] = useState(''); // Default to active (1)
  const [categoryName, setCategoryName] = useState('');
  const { id } = useParams();
  useEffect(() => {
    // Simulate fetching categories once
    const categories = [1, 2, 3, 4].map((id) => ({
      id,
      name: getNewsCategoryName(id),
    }));
    setCategoryList(categories);
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/news/${id}`, {
          headers: {
            "Authorization": `Bearer ${token}` // Include API key only
          },
        });
        if (!response.ok) throw new Error('Failed to fetch item details');
        const itemData = await response.json();
  
        // Set the state with the fetched item data
        setDescription(itemData.news.description);
        setSlug(itemData.news.slug);
        setTitle(itemData.news.title.toString());
        setActiveStatus(itemData.news.status.toString());
  
        // Find the category ID based on the fetched category name
        const foundCategory = categoryList.find(cat => cat.name === itemData.news.category);
        if (foundCategory) {
          setCategoryId(foundCategory.id.toString());
          setCategoryName(foundCategory.name);
        }
      } catch (error) {
        console.error('Error fetching item details:', error);
      }
    };
  
    if (categoryList.length > 0) {
      fetchNewsDetails();
    }
  }, [id, categoryList]); // Runs when id or categoryList changes
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedNews = {
      title,
      slug,
      category: categoryName, 
      description, // Rich text description
      status: Number(activeStatus),
    };

    try {
      const token = localStorage.getItem('authToken');
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/news/update/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}` // Include API key only
          },
          body: JSON.stringify(updatedNews),
        });
  
        if (!response.ok) {
          throw new Error('Failed to update item');
        }
        alert("News updated successfully");
        navigate('/news/panel'); // Redirect to the item list page
      } catch (error) {
        console.error('Error updating news:', error);
      }
  };

  return (
    <PageContainer>
      <FormWrapper>
        <FormTitle>News Panel - Update</FormTitle>
        <form onSubmit={handleSubmit}>

          {/* Category Dropdown */}
          <Select
            value={categoryId}  // Bind to categoryId (id of selected category)
            onChange={(e) => {
              const selectedCategoryId = e.target.value;
              const selectedCategory = categoryList.find(cat => cat.id === Number(selectedCategoryId));
              setCategoryId(selectedCategoryId);
              setCategoryName(selectedCategory ? selectedCategory.name : '');
            }}
            required
          >
            <option value="">Select Category</option>
            {categoryList.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </Select>

          {/* Active Status Dropdown */}
          <Select
            value={activeStatus}
            onChange={(e) => setActiveStatus(e.target.value)}
            required
          >
            <option value="">Select Status</option>
            <option value="1">Active</option>
            <option value="0">Hidden</option>
          </Select>

          {/* Title and Slug Fields */}
          <Input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            required
          />
    
        <StyledEditorWrapper>
        <StyledEditor
            value={description}
            onChange={setDescription}
            modules={{ toolbar: toolbarOptions }}
            formats={[
            'font', 'size', 'bold', 'italic', 'underline', 'strike', 'align',
            'list', 'bullet', 'indent', 'link', 'image'
            ]} 
            placeholder="Write your description here..."
        />
        </StyledEditorWrapper>

        <ButtonContainer>
            <BackButton 
                type="button" // This prevents it from being treated as a submit button
                onClick={() => {
                    navigate(-1); // Navigate back to the previous page
                }}
            >
                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
                Back
            </BackButton>
            <Button type="submit">Update News</Button>
        </ButtonContainer>
        </form>
      </FormWrapper>
    </PageContainer>
  );
};

export default NewsPanelEdit;
