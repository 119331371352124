import React, { useState, useContext} from 'react';
import styled from 'styled-components';
import { FaShoppingCart, FaRegistered } from 'react-icons/fa'; // Importing icons
import { UserContext } from '../components/UserContext'; // Adjust the import as necessary

// Styled components for the card and modal
const CardContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  width: 200px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  background-color: rgba(0, 0, 0, 0.7);

  &:hover {
    transform: scale(1.05);
  }
`;

const ItemImage = styled.img`
  width: 30%;
  height: auto;
  border-radius: 8px;
`;

const ItemTitle = styled.h3`
  font-size: 18px;
  margin: 8px 0;
  color: white;
`;

const ItemDescription = styled.p`
  font-size: 14px;
  color: white;
`;

const ItemPrice = styled.h4`
  color: white;

  .fas.fa-registered {
    color: #f39c12; /* Set the color for the icon */
  }
`;

const ModalItemPrice = styled.h4`
  color: black;

  .fas.fa-registered {
    color: #f39c12; /* Set the color for the icon */
  }
`;

const AddToCartButton = styled.button`
  padding: 8px 12px;
  background-color: #273c75;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 8px;

  &:hover {
    background-color: #3498DB;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const CloseButton = styled.button`
  background: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  flex: 1;
  margin-right: 10px; /* Margin between buttons */
`;

const BuyButton = styled.button`
  background: #27ae60;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  flex: 1;
`;

const ShopItemCard = ({ item, onAddToCart }) => {
  
  const { userData, setUserData } = useContext(UserContext);
  const imageBasePath = '../images/';
  const imagePath = `${imageBasePath}${item.ItemImage}`;
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleBuyClick = () => {
    if (userData.kbpoint < item.ItemPrice) {
      alert("You do not have enough R-coin to purchase this item.");
      handleCloseModal();
      return; // Exit the function if the user doesn't have enough points
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleBuy = async () => {
    const confirmation = window.confirm("Do you want to proceed with the purchase?");
    if (confirmation) {
        if (userData.kbpoint >= item.ItemPrice) {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/shoppurchase`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        username: userData.username,
                        productnum: item.ProductNum,
                    }),
                });

                if (response.ok) {
                    const data = await response.json();

                    // Update the kbpoint in the client-side state
                    const updatedUserData = {
                        ...userData,
                        kbpoint: data.newKbpoint, // Set the new kbpoint returned from server
                    };
                    setUserData(updatedUserData); // Update the global userData
                    localStorage.setItem('userData', JSON.stringify(updatedUserData));

                    alert(`${item.ItemName} successfully bought, Please check your bank!`);
                } else {
                    const errorData = await response.json();
                    alert(`Purchase failed: ${errorData.error}`);
                }
            } catch (err) {
                console.error("Error processing purchase:", err);
            }
        } else {
            alert("Insufficient kbpoint.");
        }
    } else {
        console.log("Purchase canceled");
    }
    handleCloseModal();
};

  return (
    <>
      <CardContainer>
        <ItemImage src={imagePath} alt={item.ItemName} />
        <ItemTitle>{item.ItemName}</ItemTitle>
        <ItemDescription>{item.description}</ItemDescription>
        <ItemPrice>
          <FaRegistered style={{ marginRight: '5px' }} className="fas fa-registered" />
          {item.ItemPrice}
        </ItemPrice>
        <AddToCartButton onClick={handleBuyClick}>
          <FaShoppingCart style={{ marginRight: '5px' }} />Buy
        </AddToCartButton>
      </CardContainer>

      {/* Modal for buying confirmation */}
      {isModalOpen && (
        <Overlay>
          <ModalContainer>
            <h3>{item.ItemName}</h3>
            <ItemImage src={imagePath} alt={item.ItemName} style={{ width: '20%', borderRadius: '8px' }} />
            <ModalItemPrice><FaRegistered style={{ marginRight: '5px' }} className="fas fa-registered" />{item.ItemPrice}</ModalItemPrice>
            <p>Do you want to purchase this item?</p>
            <ButtonContainer>
              <CloseButton onClick={handleCloseModal}>Close</CloseButton>
              <BuyButton onClick={handleBuy}>Buy</BuyButton>
            </ButtonContainer>
          </ModalContainer>
        </Overlay>
      )}
    </>
  );
};

export default ShopItemCard;
