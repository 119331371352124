import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import { UserContext } from '../components/UserContext'; // Import the UserContext
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const FormContainer = styled.div`
  width: 40%;
  background-color: #1c1c1c;
  margin: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 5px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 10px;
  margin-top: 100px;
`;

const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const SuccessMessage = styled.div`
  font-family: "Roboto", sans-serif;
  background-color: #2e7d32;
  padding: 15px;
  color: white;
  text-align: center;
`;

const FormField = styled.input`
  width: 90%;
  margin: 0;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #444;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  background: #333;
  color: #eee;

  &::placeholder {
    color: grey;
  }

  &.error {
    border-color: #ff6f61;
  }
`;

const ErrorSpan = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #ff6f61;
  margin: 5px 0;
`;

const SubmitButton = styled.button`
  margin-top: 20px;
  background: #273c75;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;

  &:disabled {
    cursor: not-allowed;
    background: #004d40;
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

const FormFieldContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const BackButton = styled.button`
  
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: #888;
  }
`;

const ChangePasswordPage = React.memo(() => {
  const [values, setValues] = useState({
    oldPassword: "",
    confirmOldPassword: "",
    password: "",
    confirmPassword: "",
    pincode: "",
    confirmPincode: "",
  });

  const { userData, setUserData } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const [serverError, setServerError] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const navigate = useNavigate();

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(values);
    if (Object.keys(validationErrors).length === 0) {
      setValid(true);
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/updatepassword`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({
            userId: userData?.username,
            oldPassword: values.oldPassword,
            password: values.password,
            pincode: values.pincode,
          }),
        });
        if (response.ok) {
          setUserData({ ...userData }); // Ensure user data is updated
          localStorage.setItem('userData', JSON.stringify(userData));
          setSubmitted(true);
          setSuccessMessage("Your password has been updated successfully!"); // Set success message
          setServerError(""); // Clear any previous server errors
          // Navigate to home after 2 seconds
          setTimeout(() => {
            navigate("/"); // Adjust the path as necessary
          }, 2000);
        } else {
          const data = await response.json();
          setServerError(data.error || "Update failed");
          setSuccessMessage(""); // Clear success message on error
        }
      } catch (err) {
        console.error("Error updating user:", err);
        setServerError("Update failed");
        setSuccessMessage(""); // Clear success message on error
      }
    } else {
      setValid(false);
    }
    setErrors(validationErrors);
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.oldPassword) errors.oldPassword = "Please enter your old password";
    if (values.oldPassword !== values.confirmOldPassword) errors.confirmOldPassword = "Old passwords do not match";
    if (!values.password) errors.password = "Please enter a new password";
    if (values.password !== values.confirmPassword) errors.confirmPassword = "Passwords do not match";
    if (!values.pincode) errors.pincode = "Please enter a pincode";
    if (values.pincode !== values.confirmPincode) errors.confirmPincode = "Pincodes do not match";

    return errors;
  };

  return (
    <FormContainer>
         <BackButton onClick={() => navigate(-1)}> {/* Navigates back to the previous page */}
    <FontAwesomeIcon icon={faArrowLeft} />    Back
      </BackButton>
      <RegisterForm onSubmit={handleSubmit}>
        {successMessage && (
          <SuccessMessage>{successMessage}</SuccessMessage> // Display success message
        )}
        <FieldGroup>
          <FormFieldContainer>
            <FormField
              type="password"
              placeholder="Old Password"
              name="oldPassword"
              value={values.oldPassword}
              onChange={handleInputChange}
              className={errors.oldPassword ? "error" : ""}
            />
            {errors.oldPassword && (
              <ErrorSpan id="old-password-error">{errors.oldPassword}</ErrorSpan>
            )}
          </FormFieldContainer>
          <FormFieldContainer>
            <FormField
              type="password"
              placeholder="Confirm Old Password"
              name="confirmOldPassword"
              value={values.confirmOldPassword}
              onChange={handleInputChange}
              className={errors.confirmOldPassword ? "error" : ""}
            />
            {errors.confirmOldPassword && (
              <ErrorSpan id="confirm-old-password-error">
                {errors.confirmOldPassword}
              </ErrorSpan>
            )}
          </FormFieldContainer>
        </FieldGroup>
        <FieldGroup>
          <FormFieldContainer>
            <FormField
              type="password"
              placeholder="New Password"
              name="password"
              value={values.password}
              onChange={handleInputChange}
              className={errors.password ? "error" : ""}
            />
            {errors.password && (
              <ErrorSpan id="password-error">{errors.password}</ErrorSpan>
            )}
          </FormFieldContainer>
          <FormFieldContainer>
            <FormField
              type="password"
              placeholder="Confirm New Password"
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={handleInputChange}
              className={errors.confirmPassword ? "error" : ""}
            />
            {errors.confirmPassword && (
              <ErrorSpan id="confirm-password-error">
                {errors.confirmPassword}
              </ErrorSpan>
            )}
          </FormFieldContainer>
        </FieldGroup>
        <FieldGroup>
          <FormFieldContainer>
            <FormField
              type="text"
              placeholder="Pincode"
              name="pincode"
              value={values.pincode}
              onChange={handleInputChange}
              className={errors.pincode ? "error" : ""}
            />
            {errors.pincode && (
              <ErrorSpan id="pincode-error">{errors.pincode}</ErrorSpan>
            )}
          </FormFieldContainer>
          <FormFieldContainer>
            <FormField
              type="text"
              placeholder="Confirm Pincode"
              name="confirmPincode"
              value={values.confirmPincode}
              onChange={handleInputChange}
              className={errors.confirmPincode ? "error" : ""}
            />
            {errors.confirmPincode && (
              <ErrorSpan id="confirm-pincode-error">
                {errors.confirmPincode}
              </ErrorSpan>
            )}
          </FormFieldContainer>
        </FieldGroup>
        {serverError && (
          <ErrorSpan>{serverError}</ErrorSpan> // Display server error message
        )}
        <SubmitButton type="submit">
          Update Password
        </SubmitButton>
      </RegisterForm>
    </FormContainer>
  );
});

export default ChangePasswordPage;
